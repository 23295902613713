import React from "react"
import './components.css'

const QuestionAnswering = () => {
    return(
    <div className="question_answering">
        <iframe className="question_answering-frame" data-anvil-embed src="https://URFZUIS46E7YATS4.anvil.app/RDQVE5SF2MAMXCSDCLRU3YHZ"></iframe>
    </div>
    
    );
}

export default QuestionAnswering;