import React from "react"
import './components.css'
import logo from '../components/aidl-logo.png'

const Header = () => {
    return(
    <div >
        <div className="header">
            <img className="logo" src={logo}/>
            <ul className="menu">
                <li><a>Q & A</a></li>
                <li><a>Super Mario</a></li>
                <li><a>Motor Function</a></li>
            </ul>
        </div>
    </div>
    
    );
}

export default Header;