import './App.css';
import QuestionAnswering from './components/QuestionAnswering';
import Header from '../src/components/Header';

function App() {
  const script = document.createElement("script");
  script.src = "https://anvil.works/embed.js";
  script.async = true;
  document.body.appendChild(script);
  return (
    <div className="App">
      <header className="App-header">
        <Header/>
      </header>
        <QuestionAnswering/>
      <footer>

      </footer>
    </div>
  );
}

export default App;
